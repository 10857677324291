import React from "react";
import "./navBar.css";

const NavBar = () => {
  return (
    <>
      <nav class="navbar">
        <div class="logo">
          <img src=".././images/logo2.svg" alt="Kapela Polarita logo" draggable="false"></img>
        </div>
        <h1 style={{ padding: "0" }}>Kapela Polarita</h1>
      </nav>
    </>
  );
};

export default NavBar;
