import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";

import "./home.css";
import NavCard from "../../components/navCard/navCard";
import NavBar from "../../components/navBar/navBar";
import MemberCard from "../../components/memberCard/memberCard";
import OurProduction from "../../components/ourProduction/ourProduction";
import EventCard from "../../components/eventCard/eventCard";
import AparaturaSlider from "../../components/aparaturaSlider/aparaturaSlider";
import GalleryPage from "../../components/galleryPage/galleryPage";
import Paralax from "../../components/paralax/paralax";
import SongTable from "../../components/songTable/songTable";

const imagesAparatura = [".././images/aparatura1.jpeg", ".././images/aparatura7.jpeg", ".././images/gallery/gallery9.jpeg", ".././images/aparatura3.jpeg", ".././images/aparatura4.jpeg", ".././images/aparatura5.jpeg"];

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Kapela Polarita</title>
        <meta name="Kapela Polarita" content="Kapela Polarita" />
      </Helmet>
      <section className="page-wrap">
        <section className="first-page">
          <div id="VideoContainer">
            <video id="Video" autoPlay="autoplay" muted loop playsInline preload="metadata">
              <source src="./images/video.mp4" type="video/mp4" />
            </video>
          </div>
          <NavBar />
          <div className="center padding-mp" style={{ paddingTop: "8rem", paddingBottom: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
            <div className="container-fluid">
              <div className="row" style={{ paddingBottom: "2rem" }}>
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  {" "}
                  <div className="center logo-main">
                    <img src=".././images/logo2.svg" draggable="false" alt="Kapela Polarita" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="soc_ico center" style={{ justifyContent: "flex-start", height: "100%", alignItems: "flex-end", flexDirection: "column", marginTop: "5rem", marginRight: "1rem" }}>
                    <a href="https://www.facebook.com/polaritaofficial" aria-label="Facebook kapela Polarita" target="_blank">
                      <i class="bx bxl-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/polaritamarek/" aria-label="Instagram kapela Polarita" target="_blank">
                      <i class="bx bxl-instagram"></i>
                    </a>
                    <a href="https://open.spotify.com/artist/2lYBxNY2do2UlgYDRbobGg" aria-label="Spotify kapela Polarita" target="_blank">
                      <i class="bx bxl-spotify"></i>
                    </a>
                    <a href="https://music.apple.com/cz/artist/polarita-marek-lejhanec/1479549345" aria-label="Apple music kapela Polarita" target="_blank">
                      <i class="bx bxl-apple"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="center">
                <h1>Profesionální kapela Polarita zajišťuje kompletní zábavu pro svatby, oslavy, večírky a městské akce. Naše služby zahrnují DJ-ing, moderování, živá vystoupení a pronájem profesionální světelné a zvukové aparatury. Zajistěte si s námi nezapomenutelný zážitek.</h1>
              </div>
              <hr className="styledHr" />
              <div className="carusel center">
                <OurProduction />
              </div>
            </div>
          </div>
        </section>
        <section className="nav-page" style={{ justifyContent: "flex-start" }}>
          <div className="container navcard-container" style={{ padding: "4rem 1.5rem" }}>
            <div className="row">
              <NavCard title="Svatby" href="https://svatebniskupina.cz/" />
              <NavCard title="Obsazení kapely" href="#obsazeniKapely" />
              <NavCard title="Galerie" href="#gallery-page" />
              <NavCard title="Koncerty" href="#eventPage" />
              <NavCard title="Repertoár" href="#repertoar" />
              <NavCard title="Aparatura" href="#aparatura" />
              <NavCard title="Naše tvorba" href="https://www.youtube.com/@mareklejha" />
              <NavCard title="Kontakt" href="#contact" />
            </div>
          </div>

          <div className="container" style={{ padding: "4rem 1.5rem" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="aboutUsCard">
                  <h1>Kdo jsme</h1>
                  <hr className="styledHr" style={{ marginBottom: "1rem", width: "90%" }} />
                  <p>Skupina Polarita je složena z profesionálních muzikantů, kteří milují muziku a do vystoupení vždy dají něco víc...</p>
                  <p>Založena byla roku 2015 a ve složení akustická/elektrická kytara, viola, bicí a basa je beze změn dodnes. Oslavy, svatby, firemní večírky, plesy, soukromé akce ať už na zahradní párty nebo v sálech.</p>
                  <p>Zajišťujeme kompletní zábavu večera, moderování akcí, vyhlašování tomboly, soutěže i ostatní akce.</p>
                </div>
              </div>
              <div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
                <div className="image-card">
                  <img src=".././images/band.jpeg" draggable="false" alt="Kapela Polarita"></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Paralax title="Obsazení Kapely" hrefId="obsazeniKapely" />
        <section className="memberPage" style={{ minHeight: " 145%" }}>
          <div className="center" style={{ flexDirection: "column" }}>
            <div className="container">
              <h1>Složení skupiny Polarita: </h1>
              <div className="row">
                <MemberCard name="Marek Lejhanec" instrument="kytara, zpěv" backgroundImageUrl="images/mareklejhanec.jpg" alt="Marek Lejhanec" />
                <MemberCard name="Jiří Doucha" instrument="basa, zpěv" backgroundImageUrl="images/jiridoucha.jpeg" alt="Jiří Doucha" />
                <MemberCard name="Michal Benda" instrument="viola" backgroundImageUrl="images/michalbenda.jpeg" alt="Michal Benda" />
                <MemberCard name="Mika Ronos" instrument="bicí" backgroundImageUrl="images/mikaronos.jpg" alt="Mika Ronos" />
              </div>
            </div>
            <div className="container">
              <h1>Alternace: </h1>
              <div className="row">
                <MemberCard name="Jaromír Hutla" instrument="DJ" backgroundImageUrl="images/hutla.jpg" alt="Jaromír Hutla" />
                <MemberCard name="Jiří Kožnar" instrument="klávesy" backgroundImageUrl="images/koznar.png" alt="Jiří Kožnar" />
                <MemberCard name="Veronika Hložková" instrument="zpěv" backgroundImageUrl="images/hlozkova.jpg" alt="Veronika Hložková" />
                <MemberCard name="Petr Mifek" instrument="bicí" backgroundImageUrl="images/mifek.png" alt="Petr Mifek" />
                <MemberCard name="Matouš Novák" instrument="bicí" backgroundImageUrl="images/novak.png" alt="Matouš Novák" />
              </div>
            </div>
          </div>
        </section>

        <Paralax title="Galerie" hrefId="gallery-page" />
        <section className="gallery-page" style={{ justifyContent: "flex-start", minHeight: "80%" }}>
          <GalleryPage />
        </section>
        <Paralax title="Koncerty" hrefId="eventPage" />
        <section className="eventPage" style={{ padding: "1rem 0 4rem 0", minHeight: "40%" }}>
          <div className="container"></div>
          <EventCard />
        </section>
        <Paralax title="Repertoár" hrefId="repertoar" />
        <section className="repertoarPage center" style={{ justifyContent: "flex-start" }}>
          <SongTable />
        </section>
        <Paralax title="Aparatura" hrefId="aparatura" />

        <section className="aparatura_page nav-page" style={{ minHeight: "80%" }}>
          <div className="container" style={{ padding: "4rem 1.5rem" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="aboutUsCard">
                  <h1>Bose L1 a Bose F1</h1>
                  <hr className="styledHr" style={{ marginBottom: "1rem" }} />
                  <p>Vlastníme profesionální aparaturu Bose L1 a Bose F1, která ozvučí většinu prostorů našich klientů s výkonem 7KW.</p>
                  <p>Vlastní světelná aparatura je od Chauvet Gigbar 2,0 - 2x sloup.</p>
                  <p>V případě velkých městských akcí máme své zvukaře , stage i světelnou aparaturu.</p>
                  <p>Nezáleží nám na tom, jak velká akce to je, jde o to, aby zvuk byl vždy naprosto ohromující.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image-card">
                  <AparaturaSlider imagesAparatura={imagesAparatura} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*}
        <div id="naseTvorba">
          <div id="paralax-image">
            <div className="paralax">
              <h3>naše tvorba</h3>
            </div>
          </div>
        </div>
        
        <section className="ourProduction_page">
          <div className="carusel center">
            <OurProduction />
          </div>
          <div className="center">
            {" "}
            <iframe style={{ borderRadius: "12px", width: "90%", height: "352px", border: "0", margin: "4rem 0" }} src="https://open.spotify.com/embed/artist/2lYBxNY2do2UlgYDRbobGg?utm_source=generator&theme=0" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </section>
  {*/}
        <section className="contact" id="contact">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 style={{ color: "white" }}>Kontaktujte nás</h1>
            <hr className="styledHr" style={{ marginBottom: "2rem" }} />
            <div className="contact-info">
              <div className="center">
                <p style={{ color: "white" }}>
                  <strong>Email:</strong>
                  <a href="mailto:Info@kapelapolarita.cz" style={{ color: "white" }}>
                    Info@kapelapolarita.cz
                  </a>
                </p>
                <p style={{ color: "white" }}>
                  <strong>Telefon:</strong>{" "}
                  <a style={{ color: "white" }} href="tel:603559767">
                    +420 603 559 767
                  </a>
                </p>
              </div>
              <p style={{ fontSize: "16px", marginTop: "1rem", color: "white" }}>
                <strong>Kontaktní informace:</strong>{" "}
                <a href="https://maps.app.goo.gl/BkbsjmkM5vBKEHqB8" target="_blank" aria-label="Adresa kapela Polarita" style={{ fontSize: "16px", color: "white" }}>
                  Marek Lejhanec, Škrovád 154, 53821 Slatiňany
                </a>
              </p>
            </div>

            <div className="social-icons">
              <a href="https://www.facebook.com/polaritaofficial" aria-label="Facebook kapela Polarita" target="_blank" rel="noopener noreferrer">
                <i className="bx bxl-facebook-circle"></i>
              </a>
              <a href="https://www.instagram.com/polaritamarek/" aria-label="Instagram kapela Polarita" target="_blank" rel="noopener noreferrer">
                <i className="bx bxl-instagram-alt"></i>
              </a>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Home;
