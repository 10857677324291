import React from "react";
import "./memberCard.css";

const MemberCard = (props) => {
  return (
    <>
      <div className="col-md-3">
        <div className="card-wrap center">
          <div class="card">
            <div className="card2" style={{ backgroundImage: `url(${props.backgroundImageUrl})` }} title={props.alt}></div>
          </div>
          <h3>{props.name}</h3>
          <p>{props.instrument}</p>
        </div>
      </div>
    </>
  );
};

export default MemberCard;
