import React, { useState, useEffect } from "react";

import "./galleryPage.css";

import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const GalleryPage = () => {
  const images = [
    { id: 4, alt: "Kapela Polarita na koncertu" },
    { id: 5, alt: "Členové kapely Polarita během zkoušky" },
    { id: 13, alt: "Kapela Polarita akce" },
    { id: 14, alt: "Koncertní vystoupení kapely Polarita" },
    { id: 16, alt: "Kapela Polarita s fanoušky" },
    { id: 15, alt: "Marek Lejhanec člen kapely Polarita" },
    { id: 6, alt: "Kapela Polarita ve studiu" },
    { id: 7, alt: "Kapela Polarita na festivalu" },
    { id: 8, alt: "Pódium před koncertem kapely Polarita" },
    { id: 9, alt: "Kapela Polarita během vystoupení" },
    { id: 10, alt: "Kapela Polarita při nahrávání" },
    { id: 11, alt: "Člen kapely Polarita při hře na kytaru" },
    { id: 12, alt: "Kapela Polarita v zákulisí" },
    { id: 1, alt: "Michal Benda člen kapely Polarita" },
    { id: 2, alt: "Jiří Doucha člen kapely Polarita" },
    { id: 3, alt: "Mika Ronos člen kapely Polarita" },
  ].map(({ id, alt }) => ({
    src: `.././images/gallery/gallery${id}.jpeg`,
    alt,
  }));

  return (
    <>
      <div className="Gallery-page">
        <section id="GalleryCard">
          <div className="container-fluid">
            <div className="slideshow">
              <Carousel images={images} style={{ height: 650, width: 1500 }} playIcon={false} pauseIcon={false} leftIcon={<i class="bx bx-chevron-left"></i>} rightIcon={<i class="bx bx-chevron-right"></i>} maxIcon={<i class="bx bx-expand-alt"></i>} minIcon={<i class="bx bx-x"></i>} objectFit={"contain"} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GalleryPage;
