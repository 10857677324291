import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import AnimatedRoutes from "./components/AnimatedRoutes/AnimatedRoutes";

function App() {
{/*}
 
  {
    const [hasAccess, setHasAccess] = useState(false);
    const correctPassword = "navrhpolarita";

    useEffect(() => {
      const userPassword = prompt("Please enter the password:");
      if (userPassword === correctPassword) {
        setHasAccess(true);
      } else {
        alert("Access denied!");
        window.location.reload();
      }
    }, [correctPassword]);

    if (!hasAccess) {
      return null;
    }
  }

{*/}

  return (
    <>
      {
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      }
    </>
  );
}

export default App;
