import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import EventCardEdit from "../eventCardEdit/eventCardEdit";

import "./admin.css";

const Admin = () => {
  const storedAccess = localStorage.getItem("hasAccess") === "true";
  const [hasAccess, setHasAccess] = useState(storedAccess);
  const correctPassword = "adminpolarita";
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [sidebarActiveClass, setSidebarActiveClass] = useState(1);

  useEffect(() => {
    if (storedAccess) {
      return;
    }

    const userPassword = prompt("Please enter the password:");

    if (userPassword === null) {
      alert("Access denied!");
      return;
    }

    if (userPassword === correctPassword) {
      setHasAccess(true);
      localStorage.setItem("hasAccess", "true");
    } else {
      alert("Access denied!");
    }
  }, [storedAccess, correctPassword]);

  if (!hasAccess) {
    return null;
  }

  function handleMenuBtnClick() {
    setIsSidebarActive((prevIsSidebarActive) => !prevIsSidebarActive);
  }

  return (
    <>
      <Helmet>
        <title>Adminpanel | Polarita</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <section className="main" style={{ minHeight: "unset" }}>
        <div className={`sidebar ${isSidebarActive ? "active" : ""}`}>
          <div className="top">
            <div className="logo">
              <img src=".././images/logo2.svg" alt="Logo" draggable="false"></img>
            </div>
            <i class="bx bx-menu" id="menu_btn" onClick={handleMenuBtnClick}></i>
          </div>

          <ul>
            <li onClick={() => setSidebarActiveClass(1)}>
              <a href="#">
                <i class="bx bx-party"></i>
                <span className="nav_item">Koncerty</span>
              </a>
            </li>
            <li id="lastchild">
              <a href="https://kapelapolarita.cz/">
                <i class="bx bx-log-out-circle"></i>
                <span className="nav_item">Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="main_content">
          <div className="container">
            {sidebarActiveClass && sidebarActiveClass === 1 && (
              <>
                <section className="section_adduser" style={{ minHeight: "unset" }}>
                  <EventCardEdit />
                </section>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Admin;
