import React, { useEffect, useState } from "react";
import "./eventCard.css";

const EventCard = () => {
  const [eventData, setEventData] = useState([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  const handleEventClick = (index) => {
    setSelectedEventIndex(index);
  };

  useEffect(() => {
    fetch("https://kapelapolarita.cz/server/")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.events)) {
          setEventData(data.events);
        } else {
          console.error("Data nejsou pole:", data.events);
        }
      })
      .catch((error) => console.error("Chyba při načítání dat:", error));
  }, []);

  return (
    <div>
      {eventData.map((event, index) => (
        <div className="event" key={index} onClick={() => handleEventClick(index)}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="event-card-wrap" title={`Kapela polarita - ${event.place}`}>
                  <div className="event-details" title={`Kapela polarita - ${event.place}`}>
                    <h3>{event.month}</h3>
                    <h1>{event.date}</h1>
                    <h3>{event.year}</h3>
                  </div>
                  <div className="event-details center event_flexStart" style={{ flexDirection: "column" }}>
                    <h3>
                      {event.day}
                      {"  "}
                      {event.time}
                    </h3>
                    <h1 className="event_width">{event.place}</h1>
                    {/*} <h3>POLARITA</h3>{*/}
                  </div>
                  <div className="eeeevent-card-image"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventCard;
