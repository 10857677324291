import React, { useState, useEffect } from "react";
import "./eventCardEdit.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EventCardEdit = () => {
  const [eventData, setEventData] = useState([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  
  const handleEventClick = (index) => {
    setSelectedEventIndex(index);
  };

  const handleRemoveEvent = () => {
    if (selectedEventIndex !== null) {
      const updatedEvents = eventData.filter((event, index) => index !== selectedEventIndex);
      setEventData(updatedEvents);
      setSelectedEventIndex(null);
      handleSubmit()
    }
  };

  const handleAddEvent = () => {
    const newEvent = {
      month: "",
      date: "",
      year: "",
      day: "",
      time: "",
      place: "",
    };
    const updatedEvents = [...eventData, newEvent];
    setEventData(updatedEvents);
    setSelectedEventIndex(updatedEvents.length - 1);
    handleSubmit()
  };

  const handleMoveEvent = (direction) => {
    if (selectedEventIndex !== null) {
      const newIndex = selectedEventIndex + direction;
      if (newIndex >= 0 && newIndex < eventData.length) {
        const updatedEvents = [...eventData];
        [updatedEvents[selectedEventIndex], updatedEvents[newIndex]] = [updatedEvents[newIndex], updatedEvents[selectedEventIndex]];
        setEventData(updatedEvents);
        setSelectedEventIndex(newIndex);
        handleSubmit()
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let arr = { events: [] };
    arr.events = eventData;
    axios
      .post('https://kapelapolarita.cz/server/', arr)
      .then(function (response) {
        console.log(response.data);
        toast.success('Úspešně uloženo');
      })
      .catch(function (error) {
        console.log(error);
        toast.error('Něco se pokazilo');
      });
  };

  useEffect(() => {
    fetch("https://kapelapolarita.cz/server/").then(function(response) {
      return response.text();
  })
  .then(function(data) {
      let res = JSON.parse(data)
      //console.log(res.events);
      if (Array.isArray(res.events)) {
        setEventData(res.events);
        setSelectedEventIndex(null);
      } else {
        console.error("Data nejsou pole:", res.events);
      }
  });
  }, []);

  return (
    <>
    <ToastContainer
position="top-right"
autoClose={1000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
>
</ToastContainer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5 style={{ fontSize: "1.5rem" }}>KONCERTY</h5>
          </div>
          <div className="col-md-6">
            <div className="buttons">
              <button type="button" className="add_button" onClick={handleAddEvent}>
                Přidat koncert
              </button>
              <button type="button" className="submit_button" onClick={handleSubmit}>
                    Uložit
                  </button>
              {selectedEventIndex !== null && (
              
                <>
                 
                  <button type="button" className="remove_button" onClick={handleRemoveEvent}>
                    Odebrat koncert
                  </button>
                  <button type="button" className="move_button" onClick={() => handleMoveEvent(-1)}>
                    <i class="bx bxs-chevron-up"></i>
                  </button>
                  <button type="button" className="move_button" onClick={() => handleMoveEvent(1)}>
                    <i class="bx bxs-chevron-down"></i>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <hr className="styledHr" style={{ marginTop: ".5rem" }} />
      <div className="controls">
        <form className="adduser_form">
          <div className="container">
            <div className="row">
              {selectedEventIndex !== null && eventData[selectedEventIndex] && (
                <>
                  <div className="col-md-6">
                    {" "}
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Datum"
                      name="date"
                      value={eventData[selectedEventIndex].date}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[selectedEventIndex] };
                        updatedEventData.date = e.target.value;
                        setEventData([...eventData.slice(0, selectedEventIndex), updatedEventData, ...eventData.slice(selectedEventIndex + 1)]);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Rok"
                      name="year"
                      value={eventData[selectedEventIndex].year}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[selectedEventIndex] };
                        updatedEventData.year = e.target.value;
                        setEventData([...eventData.slice(0, selectedEventIndex), updatedEventData, ...eventData.slice(selectedEventIndex + 1)]);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Den"
                      name="day"
                      value={eventData[selectedEventIndex].day}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[selectedEventIndex] };
                        updatedEventData.day = e.target.value;
                        setEventData([...eventData.slice(0, selectedEventIndex), updatedEventData, ...eventData.slice(selectedEventIndex + 1)]);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Čas"
                      name="time"
                      value={eventData[selectedEventIndex].time}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[selectedEventIndex] };
                        updatedEventData.time = e.target.value;
                        setEventData([...eventData.slice(0, selectedEventIndex), updatedEventData, ...eventData.slice(selectedEventIndex + 1)]);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="adduser_input"
                      type="text"
                      placeholder="Místo"
                      name="place"
                      value={eventData[selectedEventIndex].place}
                      onChange={(e) => {
                        const updatedEventData = { ...eventData[selectedEventIndex] };
                        updatedEventData.place = e.target.value;
                        setEventData([...eventData.slice(0, selectedEventIndex), updatedEventData, ...eventData.slice(selectedEventIndex + 1)]);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="event-cards-container">
        {eventData.map((event, index) => (
          <div className={`event ${selectedEventIndex === index ? "selected-event" : ""}`} key={index} onClick={() => handleEventClick(index)}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="event-card-wrap">
                    <div className="event-details">
                      <h3>{event.month}</h3>
                      <h1>{event.date}</h1>
                      <h3>{event.year}</h3>
                    </div>
                    <div className="event-details center" style={{ flexDirection: "column" }}>
                      <h3>
                        {event.day}
                        {" - "}
                        {event.time}
                      </h3>
                      <h1>{event.place}</h1>
                      <h3>POLARITA</h3>
                    </div>
                   <div className="event-card-image"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EventCardEdit;
