import React from "react";
import "./aparaturaSlider.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const AparaturaSlider = (props) => {
  const { imagesAparatura } = props;

  return (
    <div className="aparatura-slider">
      <Splide
        options={{
          type: "loop",
          direction: "ttb",
          height: "18rem",
          gap: "2rem",
        }}
      >
        {imagesAparatura.map((imageUrl, index) => (
          <SplideSlide key={index}>
            <img src={imageUrl} className="card-image" alt={`Aparatura k zapůjčení`} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default AparaturaSlider;
