import React, { useState } from "react";
import "./ourProduction.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Modal from "react-modal";

const OurProduction = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  const openModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideoUrl("");
  };

  return (
    <>
      <div className="ourProduction">
        <Splide
          options={{
            type: "loop",
            gap: "1rem",
            breakpoints: {
              2400: {
                perPage: 5,
                perMove: 1,
              },
              1560: {
                perPage: 4,
                perMove: 1,
              },
              1024: {
                perPage: 2,
                perMove: 1,
              },
              768: {
                perPage: 1,
                perMove: 1,
              },
            },
          }}
        >
          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/xw0usSIO2gk")}>
              <img src="./images/prew1.jpeg" className="card-image" alt="Polarita - Pocit" />
              <span className="card-text">Polarita - Pocit (Official Video)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/ExS3v-4dEYY")}>
              <img src="./images/prew2.jpeg" className="card-image" alt="Polarita - KARKULKA" />
              <span className="card-text">Polarita - KARKULKA (OFFICIAL MUSIC VIDEO)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/b-mWntIYlI8")}>
              <img src="./images/prew3.jpeg" className="card-image" alt="Polarita - Ta nej..." />
              <span className="card-text">Polarita - Ta nej... (OFFICIAL MUSIC VIDEO)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>
          {/*}
          <SplideSlide>
            <div className="splide__slide" onClick={() => openModal("https://www.youtube.com/embed/0S6TupvazA8")}>
              <img src="./images/prew4.jpeg" className="card-image" />
              <span className="card-text">Polarita - Maličkost (Official Video)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>
        {*/}
          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/bIWcWHRii3M")}>
              <img src="./images/prew7.png" className="card-image" alt="POLARITA - ZRNKO feat VERONIKA WILDOVÁ" />
              <span className="card-text">POLARITA - ZRNKO feat VERONIKA WILDOVÁ (Official Video)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/EsRfsS7pkPA")}>
              <img src="./images/prew5.jpeg" className="card-image" alt="Vánoční - Polarita - Přání<" />
              <span className="card-text">Vánoční - Polarita - Přání (Official Video)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/jCoAiR-g31g")}>
              <img src="./images/prew6.jpeg" className="card-image" alt="Veronika Wildová feat. Marek Lejhanec - Hvězdy" />
              <span className="card-text">Veronika Wildová feat. Marek Lejhanec - Hvězdy (Official Video)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.youtube.com/embed/hnF1NRSxb2s")}>
              <img src="./images/prew9.jpeg" className="card-image" alt=">Polarita - Marek Lejhanec - Naděje" />
              <span className="card-text">Polarita - Marek Lejhanec - Naděje (Official Video)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fpolaritaofficial%2Fvideos%2F779396429189136%2F&show_text=false&width=560&t=0")}>
              <img src="./images/prew18.jpg" className="card-image" alt="Mlýnská párty s Polaritou v Pardubicích)" />
              <span className="card-text">Mlýnská párty s Polaritou 😉🎉🍾</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fpolaritaofficial%2Fvideos%2F2611490972513680%2F&show_text=false&width=560&t=0")}>
              <img src="./images/prew15.jpg" className="card-image" alt="Polarita hrála v Chrudimi" />
              <span className="card-text">Polarita hrála v Chrudimi</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>
          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fpolaritaofficial%2Fvideos%2F2381553518816414%2F&show_text=false&width=560&t=0")}>
              <img src="./images/prew16.jpg" className="card-image" alt="POLARITA - ... a jak že to vlastně bylo ?? 🎻🎸🎤🚴‍♀️" />
              <span className="card-text">... a jak že to vlastně bylo ?? 🎻🎸🎤🚴‍♀️</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>
          <SplideSlide role="group">
            <div className="splide__slide" role="group" onClick={() => openModal("https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fpolaritaofficial%2Fvideos%2F279519103371436%2F&show_text=false&width=560&t=0")}>
              <img src="./images/prew17.jpg" className="card-image" alt="POLARITA - Ta nej..." />
              <span className="card-text">Ta nej...</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>
          {/*}
          <SplideSlide>
            <div className="splide__slide" onClick={() => openModal("https://www.youtube.com/embed/wRd58da7pqw")}>
              <img src="./images/prew10.png" className="card-image" />
              <span className="card-text">Marek Lejhanec - Promo</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div className="splide__slide" onClick={() => openModal("https://www.youtube.com/embed/HKfBBsuRMsM")}>
              <img src="./images/prew11.png" className="card-image" />
              <span className="card-text">Praha Acoustic Cover 2017 (Marek Lejhanec )</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div className="splide__slide" onClick={() => openModal("https://www.youtube.com/embed/VOaQSELyTjQ")}>
              <img src="./images/prew12.png" className="card-image" />
              <span className="card-text">Na španělských schodech Acoustic Cover 2017 (Marek Lejhanec)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div className="splide__slide" onClick={() => openModal("https://www.youtube.com/embed/2VDtjdTcEUQ")}>
              <img src="./images/prew13.png" className="card-image" />
              <span className="card-text">Slunečný hrob Acoustic Cover 2017 (Marek Lejhanec)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div className="splide__slide" onClick={() => openModal("https://www.youtube.com/embed/H8iDrKWenoE")}>
              <img src="./images/prew14.png" className="card-image" />
              <span className="card-text">Až.... Acoustic Cover 2017(Marek Lejhanec)</span>
              <i className="bx  bx-play card-icon"></i>
            </div>
          </SplideSlide>
      {*/}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Video Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.95)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                transform: "translate(-50%, -50%)",
                width: "80%",
                height: "80%",
              },
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src={currentVideoUrl}
              title="YouTube video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style={{
                border: "none !important",
                zIndex: 10,
                position: "relative",
              }}
            ></iframe>
          </Modal>
        </Splide>
      </div>
    </>
  );
};

export default OurProduction;
